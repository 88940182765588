import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: [
        'Inter', 
        'sans-serif'
      ].join(','),
    }
  },
  palette: {
    primary: {
      main: `#0095FF`,
    }
  }
});

export default theme;